@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer utilities {
  .ml-biggest {
    margin-left: 10px; /* Adjust the value to your desired biggest margin */
  }
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));


}

.bg-light-blue{
  background-color: #00a9db;
}
 
  