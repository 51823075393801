@import "../sass/abstracts/_variable.scss";
@import "../Components/Table/table.scss";
@import "../Components/StudentTable/table.scss";
#interface {
  width: calc(100% - 300px);
  margin-left: 300px;
  position: relative;
  height: auto;
  transition: 0.3s ease;
  background-color: $primary-color;
}

#interface .navigation {
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $primary-color;
  padding: 15px 30px;
  border-bottom: 2px solid $accent;
  position: fixed;
  width: calc(100% - 300px);
  padding-bottom: 1rem;
}

#interface .navigation .profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  right: 0;
  margin-top: 1rem;
}
.profile i {
  padding-right: 20px;
  font-size: 25px;
}
.profile .vl {
  border-left: 4px solid $primarydark-color;
  height: 40px;
  padding-right: 20px;
}
#interface .navigation .profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
#interface .navigation .profile .name {
  margin-left: 10px;
  color: var(--second-color);
  font-size: 13px;
  font-weight: 300;
  text-transform: capitalize;
}

#menu-btn {
  color: var(--second-color);
  font-size: 30px;
  cursor: pointer;
  margin-right: 20px;
  display: none;
}

// content design layout
#interface .content {
  padding: 30px;
  margin-top: 60px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  background: $primary-color;
  min-height: calc(100vh - 80px);
  // border-radius: 10px;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
#interface .title{
  font-weight: bold;
  font-size: 22px;
  padding: 15px 18px;
}
#interface .sub-title{
  font-weight: 400;
  font-size: 14px;
  padding: 0px 18px;
  padding-bottom: 20px;
}

.date-btn-stat{
  margin: 12px;
  border-radius:10px;
  border: 1px solid #ccc;
}
.chart-box{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.div-chart{
  margin-top: 4rem;
  height: 200px;
  width: 400px;
}
.order-title{
  font-size: 14px;
  margin-bottom: 10px;
}
.revenue-title{
  font-size: 14px;
  margin-bottom: 10px;
  padding-top: 0px;
}
/* responsive design */

@media (max-width: 769px) {
  #menu {
    width: 270px;
    position: fixed;
    top: 0;
    left: -270px;
    transition: 0.3s ease;
  }
  #menu.active {
    left: 0px;
  }
  #menu-btn {
    display: initial;
  }
  #interface {
    width: 100%;
    margin-left: 0px;
    display: inline-block;
  }
  #menu.active ~ #interface {
    width: calc(100% - 270px);
    margin-left: 270px;
  }
  #interface .navigation {
    width: 100%;
  }
  .board {
    width: 94%;
    padding: 0;
    overflow-x: auto;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
}
