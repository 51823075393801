@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

.home-container {
  position: relative;
  height: 100vh;

  .video-background {
    position: absolute;

    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
    height: 100%;

    .video {
      width: 100%;
      object-fit: cover;
      -o-object-fit: cover;
      height: 100%;
    }
    .overlay {
      background-image: linear-gradient(
        to left bottom,
        rgba(19, 61, 103, 0.527),
        rgba(0, 0, 0, 0.514)
      );
      width: 100%;
      height: 100vh;
      position: absolute;

      top: 0;
      left: 0;
      .overlay-content {
        width: 100%;
        height: 100%;
        padding: 80px 0;
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        &--content {
          width: 78%;
          .welcome-text {
            font-size: 24px;
            text-align: center;
            font-weight: 500;
            color: $color-bg-white;
            line-height: 1.1;
          }
          .list {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 30px;
            gap: 30px;
            font-family: "Roboto", sans-serif;
            &-offer {
              list-style-type: none;

              text-align: left;
              li {
                color: $color-bg-white;
                font-size: 14px;
                font-weight: 500;
                display: block;
                position: relative;
                padding: 0 0 0 25px;
                &::before {
                  content: "\f00c";
                  display: inline-block;
                  font-family: "fontAwesome";
                  font-weight: inherit;
                  position: absolute;

                  left: 0px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $breakPoint-x-sm) {
  .home-container {
    .video-background {
      .overlay {
        .overlay-content {
          padding: 20px;
          &--content {
            width: 100%;
            .welcome-text {
              font-size: 18px;
              font-weight: 700;
            }
            .list {
              margin-top: 15px;
              gap: 15px;
              &-offer {
                li {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
