@import '../../sass/abstracts/_variable.scss';
.forgot-password-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f2f2f2;
  font-family: Arial, sans-serif;

  .forgot-password-title {
    font-size: 30px;
  }

  .form-top-image {
    width: 70px;
    height: 70px;
    margin-bottom: 30px;
    text-align: center;
    margin-left: 40%;
  }

  .form-description {
    // color: rgb(118, 125, 156);
    color: rgb(118, 125, 156);
    font-weight: 50;
    margin-bottom: 30px;
    text-align: center;
    font-size: 16px;
  }

  // input {
  .send-email-input {
    padding: 0.8rem;
    margin-top: 10px;
    height: 40px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 100%;
    font-size: 15px;
  }
  .send-email-button {
    color: #f2f2f2;
    background-color: #7a89e9;
    border: none;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    font-size: 15px;
    cursor: pointer;

    &:hover {
      background-color: #4e62e4;
      border: 1px solid #7a89e9;
    }
  }

  h1 {
    margin-bottom: 1rem;
    font-weight: 300;
    text-align: center;
  }
  .back-to-login-text {
    color: rgb(118, 125, 156);
    text-align: center;
    padding-top: 5px;
    cursor: pointer;
    font-size: 15px;
    display: flex;
    gap: 7px;
  }
}

//   ....................Page 2 (reset-password form)....................
.entered-email-by-user {
  text-align: center;
  color: #7a89e9;
  font-size: 16px;
}

.new-password-page-description {
  text-align: center;
}

.input-labels {
  color: black;
  font-size: 13px;
  // margin-bottom: 3px;
  padding-top: -10%;
}

.validation-error-text {
  color: red;
  margin-bottom: 6px;
  font-size: 12px;
}

.show-password-emoji {
  padding-left: 55%;
  cursor: pointer;
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 25px;
}

.below-the-submit-button {
  // border: 1px solid red;
  display: flex;
}
#dark.forgot-password-page {
  background-color: $background-dark;
  .forgot-password-title {
    color: $primary-color;
  }
  .form-description,
  .back-to-login-text {
    font-weight: 80;
    color: $accent;
  }
}
