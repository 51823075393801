@mixin hoverBackground($text-color, $bg-color) {
  color: $text-color;
  background-color: $bg-color;
}

@mixin btn($link-color, $bg-link-color, $display) {
  display: $display;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px;
  padding: 3px 30px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: $link-color;
  background-color: $bg-link-color;
  outline: 1px solid $color-text-primary;
  box-shadow: 1px 1px 3px $color-text-dark-primary;
  transition: all 0.3s;
}
