@import "../../sass/abstracts/_variable.scss";

table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $accent;
  margin: 15px 10px;
}
tr {
  border-bottom: 1px solid $accent;
  padding: 20px;
}
thead td {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  background: $primary-color;
  text-align: left;
  padding: 15px;
  left: 0;
}

tbody tr:hover {
  background: #fafdff;
  color: $primarydark-color;
}

th {
  background-color: #f8fbfc;
}
th,
td {
  font-size: 16px;
  padding: 13px;
  text-align: left;
  border-bottom: 1px solid $accent;
}
tr:nth-child(even) {
  background-color: #f8fbfc;
}

tr:nth-child(odd) {
  background-color: #ffffff;
}
.active-status {
  background-color: #e6dff7;
  color: #4654ae;
  padding: 2px 1px;
  left: 5px;
  border-radius: 17px;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
}
.delivered-status {
  background-color: $secondary-color;
  color: white;
  padding: 2px 1px;
  left: 5px;
  border-radius: 17px;
  text-align: center;
  text-transform: capitalize;
  font-weight: 500;
}
.inactive-status {
  background-color: #ffc4c4;
  color: #b71c1c;
  padding: 2px 1px;
  left: 5px;
  border-radius: 17px;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
}

.bxs-edit {
  color: #7880af;
  font-size: 24px;
  cursor: pointer;
  font-weight: 500;
}
.bxs-cog {
  color: $danger;
  font-size: 24px;
  cursor: pointer;
  font-weight: 500;
  margin-top: 7px;
}
.action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70px;
}
.pagination-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  margin-left: 15px;
}

.pagination-button {
  padding: 8px 12px;
  margin: 0 4px;
  background-color: #e0e0e0;
  color: $primarydark-color;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-pages {
  display: flex;
  align-items: center;
}

.pagination-page {
  padding: 8px 12px;
  margin: 0 4px;
  background-color: #f5f5f5;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: $primarydark-color;
  cursor: pointer;
}

.pagination-page.active {
  background-color: $secondary-color;
  color: $primary-color;
}

.pagination-page:hover {
  background-color: #e0e0e0;
}
