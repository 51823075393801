.nav.active {
  background-color: $color-bg-white;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.089);
  .nav-logo--name {
    color: $color-text-seccondary;
    &::before {
      color: $color-text-white;
      // color: $color-text-dark-primary;
      text-shadow: 1px 1px 1px $color-text;
    }
  }
  .nav-links .nav-link:visited,
  .nav-link:link {
    color: $color-text-seccondary;
  }
  .nav-links .nav-link:hover,
  .nav-link:active {
    color: $color-text-dark-primary;
  }
  .btn {
    @include hoverBackground($color-text-seccondary, transparent);
  }
  .btn:hover {
    @include hoverBackground($color-bg-white, $color-text-dark-primary);
  }
}
.nav {
  padding: 10px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;

  //   align-items: center;

  &-logo {
    display: flex;
    justify-content: center;
    gap: 3px;
    cursor: pointer;
    align-items: flex-start;
    transition: all 0.3s;
    &:hover {
      transition: all 0.3s;
      .nav-logo--img {
        transform: rotate(360deg);
      }
      .nav-logo--name {
        transform: translateY(-3px);
      }
    }
    &--img {
      height: 40px;
      width: 40px;
      box-shadow: 1px 1px 3px rgb(0, 0, 0);
      border-radius: 50%;
      transition: all 0.3s;
    }
    &--name {
      font-size: 42px;
      color: $color-text-white;
      position: relative;
      display: flex;
      text-shadow: 1px 1px 3px rgb(0, 0, 0);
      align-self: flex-start;
      font-weight: 700;
      //   background-image: linear-gradient(
      //     to right,
      //     $color-text-dark-primary,
      //     $color-text-primary,
      //     $color-text-seccondary,
      //     $color-text-white-primary
      //   );
      //   -webkit-background-clip: text;
      span {
        font-size: 10px;
        padding: 0 2px;
        display: flex;
        align-self: center;
      }
      &::before {
        content: "Education Systems";
        font-size: 12px;
        display: block;
        font-weight: 500;
        position: absolute;
        left: 6%;
        text-align: center;
        top: 70%;
        color: $color-bg-white;
      }
    }
  }
  &-links {
    display: flex;

    align-items: center;
    gap: 15px;
    justify-content: center;
    list-style-type: none;
  }
  &-link:link,
  &-link:visited {
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    padding: 3px 0;
    color: $color-bg-white;
    display: inline-block;
    position: relative;
    transition: all 0.3s ease-in 0s;
  }

  &-link:hover,
  &-link:active {
    color: $color-text-seccondary;
  }
  &-link:link {
    &::after {
      content: "";
      width: 0;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      background: $color-text-dark-primary;
      transition: all 0.3s ease-in 0s;
    }
  }
  &-link:hover::after {
    width: 100%;
  }

  .btn-container {
    display: flex;

    align-items: center;
  }
  .menu-container {
    display: none;
    align-items: center;
    justify-content: flex-end;
    i {
      font-size: 20px;
    }
  }
}
@media (max-width: $breakPoint-md) {
  body {
    position: relative;

    .nav {
      display: flex;
      padding: 10px 20px;
      justify-content: space-between;
      align-items: center;

      background-color: $color-bg-white;
      &-logo--name {
        color: $color-text-seccondary;
      }
      &-links {
        display: none;
      }
      &-links.active {
        position: absolute;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 30px;
        top: 91.3px;
        left: 0;
        gap: 15px;
        backface-visibility: hidden;
        max-height: calc(100vh - 74.39px) !important;
        flex-direction: column;
        background-color: $color-text-seccondary;
        width: 100%;
        &:before {
          content: "";

          top: 0;
          left: 0;
          position: absolute;
          width: 100%;
          height: 100vh;
        }
        .nav-links .nav-link {
          font-size: 18px;
          color: black;
        }
      }
      &-mobile {
        display: flex;
        flex-direction: column;
        background: $color-text-dark-primary;
      }
      .btn {
        display: none;
      }

      .menu-container {
        display: flex;
      }
    }
  }
}
@media (max-width: $breakPoint-x-sm) {
  body {
    position: relative;
    .nav {
      padding: 10px 20px;
      &-links.active {
        top: 74.39px;
      }

      &-logo {
        &--img {
          height: 25px;
          width: 25px;
          border-radius: 50%;
          transition: all 0.3s;
        }
        &--name {
          font-size: 32px;

          font-weight: 700;

          span {
            font-size: 8px;
          }
          &::before {
            content: "Education Systems";
            font-size: 8px;
            display: block;
            font-weight: 500;
            position: absolute;
            left: 12%;
            text-align: center;
            top: 70%;
            color: $color-bg-white;
          }
        }
      }
    }
  }
}
