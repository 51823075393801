@import '../../sass/abstracts/_variable.scss';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal Content */
.modal-content {
  background-color: #fff;
  border-radius: 4px;
  padding: 50px;
  max-width: 400px;
  width: 100%;
}

/* Modal Title */
.modal-content h2 {
  margin-top: 0;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Form Group */
.form-group {
  margin-bottom: 30px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input{
  width: 95%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.form-group select{
  width: 100%;
  padding: 12px 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  background-color: #fff;
}

/* Buttons */
.buttons {
  display: flex;
  justify-content: flex-start;
}

.buttons button {
  padding: 12px 16px;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}

.save-btn {
  background-color: $secondary-color;
  color: #fff;
  border: none;
  font-size: 16px;
}

.cancel-btn {
  background-color: #ccc;
  color: #000;
  border: none;
  margin-left: 20px;
  font-size: 16px;
}

.account-status-options {
  width: 200px;
  font-size: 17px;
  background-color: rgba(202, 202, 202, 0.6);
}
