.footer-container {
  position: relative;
  padding: 40px 20px;
  background: $color-text-dark-primary;
  color: $color-grey;
  .footer {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;

    justify-content: space-around;
    &-logo {
      p {
        font-weight: 300;
        font-size: 14px;
        margin: 30px 0;

        span {
          display: flex;
        }
      }
    }
    &-get-touch {
      p {
        font-size: 14px;
        font-weight: 300;
        span {
          display: flex;
          margin-bottom: 30px;
          justify-content: space-between;
        }
      }
    }
    label {
      font-size: 14px;
      text-transform: uppercase;
      display: flex;
      font-weight: 300;
    }
    input {
      width: 100%;
      padding: 4px;
      display: block;
      border: none;
      background-color: transparent;
      border-bottom: 1px solid $color-grey;
      margin-bottom: 20px;
    }
    &-links {
      list-style-type: none;
      display: flex;

      flex-direction: column;
      gap: 10px;
      a:link,
      a:visited {
        color: $color-grey;
        font-weight: 300;
        font-size: 14px;
      }
    }
    h3 {
      font-size: $color-grey;

      margin-bottom: 30px;
      font-size: 22px;
      font-weight: 500;
      color: #dddddd;
    }
  }
}
.footer-copyright {
  background-color: #092949;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  p {
    font-size: 16px;
    font-weight: 300;
    color: $color-grey;
  }
}
