.nav-container {
  .nav-logo {
    width: 100%;
    // padding: 6rem 0 4rem;
  }
}

.mg-3rem {
  position: absolute;
  top: 100px;
  left: 300px;

  // margin-top: 5rem !important;
}
.bg-white {
  background: rgb(255, 255, 255) !important;
}

.admin-navbar {
  width: calc(100% - 252px);
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.24);
  right: 0 !important;
  margin: 0;
  z-index: 1;
  text-align: right;
  padding: 1rem 1rem;
  background-color: rgb(255, 255, 255);
  .navbar {
    width: 100%;
    display: flex;

    justify-content: space-between;
    align-items: center;
    &-right {
      display: flex;
      gap: 0.8rem;
      .nav-bar-not {
        background-color: rgb(243 244 246);
        padding: 0.2rem 0.5rem;
        border-radius: 8px;
      }
      .profile {
        display: flex;
        align-items: center;

        &-img {
          background-color: rgb(243 244 246);
          border-radius: 8px;
          width: 35px;
          height: 35px;
          padding: 0rem;
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        // height: 100%;
      }
    }

    // width: 80%;
    // padding: 1rem;
    // margin-bottom: 2rem;
  }
}

aside {
  // background-color: aqua !important;
  z-index: 2;
  padding: 2rem;
}
