.fa-lg {
  font-size: 16px !important;
}

.login-container {
  height: 100vh;
  position: relative;
  &--form {
    position: absolute;
    top: 91.39px;

    background-image: linear-gradient(
      to right top,
      $color-text-seccondary,
      $color-text-dark-primary
    );
    height: calc(100vh - 91.39px);
    width: 100%;
  }
  .form-container {
    margin: 100px auto;
    max-width: 350px;

    .form {
      width: 100%;
      background-color: $color-bg-white;
      padding: 20px;
      border-radius: 8px;
      overflow: hidden;
      &-title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 15px;
      }
      .group-label {
        margin-bottom: 15px;
        label {
          font-size: 16px;
          display: block;
        }
        input {
          display: block;
          padding: 5px;
          width: 100%;
          font-size: 16px;
          border: 1px solid $color-text-white-primary;
        }
        .passWord-field {
          position: relative;
          span {
            position: absolute;
            top: 50%;
            right: 2%;
            cursor: pointer;
            transform: translate(0, -50%);
          }
        }
      }

      .link-container {
        display: block;
        text-align: center;
        &:not(:last-child) {
          margin: 15px 0;
        }
        .link {
          &:link,
          &:visited {
            font-size: 14px;
            color: $color-text-seccondary;
            text-decoration: none;
          }
          &:hover,
          &:active {
            font-size: 12px;
            color: $color-text-secondary-light;
            text-decoration: underline;
          }
          &-parent,
          &-student {
            display: inline-block;
            position: relative;
            padding: 0 8px 0 0;

            &:after {
              content: "";
              position: absolute;
              width: 1px;
              top: 50%;
              right: 3px;
              transform: translate(0, -50%);
              padding: 0 2px 0 0;
              height: 60%;
              background-color: $color-text;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $breakPoint-x-sm) {
  .login-container {
    &--form {
      top: 74.39px;
      padding: 0 20px;
      height: calc(100vh - 74.39px);
      width: 100%;
    }
  }
}
