.mg-top-3rem {
  margin-top: 3rem;
}
.btn-transparent {
  &:link,
  &:visited {
    @include btn($color-bg-white, transparent, block);
  }

  &:hover,
  &:active {
    @include hoverBackground($color-text-white, $color-text-dark-primary);
  }
}
.btn-sign-in {
  width: 100%;
  @include btn($color-text-white, $color-text-dark-primary, flex);
  &:link,
  &:visited {
    @include btn($color-text-white, $color-text-dark-primary, flex);
  }
}
.btn-secondary {
  @include btn($color-text-white, $color-text-dark-primary, inline-block);
  &:link,
  &:visited {
    @include btn($color-text-white, $color-text-dark-primary, inline-block);
  }
  &:hover,
  &:active {
    @include hoverBackground($color-bg-white, transparent);
  }
}

.btn {
  &:hover {
    transform: translateY(-3px);
  }
  &:active {
    transform: translateY(-1px);
    box-shadow: 1px 1px 1px $color-text-dark-primary;
  }
}
