$color-text-dark: #333;
$color-text: #555;
$color-bg-white: #fff;
$color-text-white: #eee;
$color-text-primary: #acd4e2;
$color-text-seccondary: #06abea;
$color-text-secondary-light: #06a9eaec;
$color-text-dark-primary: #133d67;
$color-text-white-primary: #bdbec0;
$background-dark: #bdbec0;
$primary-color:#acd4e2;
$primarydark-color:#133d67;
$secondary-color:#06abea;
$danger:#b71c1c;
$accent: #06abea;
$color-grey: #dddddd;
$font-size: 16px;
$font-size-heading: 48px;
$font-size-medium: 18px;
$font-size-small: 14px;
$font-size-extra-small: 14px;
$spacing: 1.5px;
$spacing-medium: 1.7px;
$spacing-large: 2px;

$breakPoint-lg: 1200px;
$breakPoint-md: 992px;
$breakPoint-sm: 768px;
$breakPoint-x-sm: 576px;
