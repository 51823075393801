.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}
.css-15v22id-MuiAccordionDetails-root {
  padding: 0px 4px !important;
  text-align: right !important;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0 !important;
}
.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 0px !important;
}
.css-ahj2mt-MuiTypography-root {
  margin-left: 12px !important;
}

.css-hip9hq-MuiPaper-root-MuiAppBar-root {
  color: black !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.css-1orzy9s {
  border: 1px solid black !important;
}
.disp {
  display: flex;
  align-items: center;
}
.acc {
  a {
    display: block;
    padding: 2px 0;
  }
}
.admin {
  width: 100%;
}
.admin-container {
  background: rgba(194, 194, 194, 7%);
  width: 100%;
  display: flex;

  .sidebar-container {
    background-color: #ffffff;
    z-index: 999;
    box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.303);
    width: 16%;

    .padd-2 {
      padding: 15px 0;
    }
    .dashboard {
      background-color: $color-text-dark-primary;
      display: flex;
      padding: 10px 30px;
      align-items: center;

      gap: 12px;
      img {
        width: 16px;
        height: 16px;
      }
      p {
        font-size: 16px;
        color: $color-bg-white;
        cursor: pointer;
      }
    }
    .admin-links {
      display: flex;
      flex-direction: column;
      padding: 10px 30px;
      gap: 12px;
      li {
        display: flex;
        gap: 12px;
        align-items: center;
        img {
          width: 16px;
          height: 16px;
        }
        a {
          font-size: 16px;
          text-decoration: none;
          color: rgba(0, 0, 0, 0.87);
        }
      }
    }
  }
  .main-container {
    width: 84%;
    background-color: rgba(0, 0, 0, 0.08);

    .navbar {
      //position: relative;
      width: 100%;
      background: $color-bg-white;
      padding: 10px;
      box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.303);
      //position: sticky;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;

      z-index: 99999999999;
    }
    .main-content {
      margin: 16px;
    }
  }
}
