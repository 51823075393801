*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}
// html {
//   font-size: 100%;
// }
body {
  box-sizing: border-box;
}
