.css-vubbuv {
  font-size: 80px !important;
}
.verifyOtp-container {
  height: 100vh;
  background-color: $color-text-white;

  .verify {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    &-account {
      h3 {
        font-size: 24px;
        // color: $color-text-dark;
        // font-weight: 700;
        margin-bottom: 20px;
        line-height: 1.4;
        text-shadow: 1px 1px 1px $color-text-dark;
        span {
          display: block;
        }
      }
      p {
        font-size: 16px;
        line-height: 1.1;
        span {
          display: block;
        }
      }
    }
    .form {
      background-color: $color-bg-white;
      padding: 80px 40px;
      width: 300px;
      box-shadow: 1px 3px 3px $color-grey;
      .form-header {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
        h3 {
          font-size: 22px;
          font-weight: 700;
        }
      }
      input {
        padding: 5px;
        display: block;
        width: 100%;
        border-radius: 8px;
        border: 1px solid $color-text;
      }
      label {
        font-size: 16px;
      }
    }
  }
}
